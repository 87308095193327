// extracted by mini-css-extract-plugin
export var container = "BlogPage-module--container--5igEf";
export var wrapper = "BlogPage-module--wrapper--N-snC";
export var title = "BlogPage-module--title--IFQbR";
export var description = "BlogPage-module--description--DuFyp";
export var descriptionActive = "BlogPage-module--descriptionActive--9CoF8";
export var categories = "BlogPage-module--categories--VMkaI";
export var category = "BlogPage-module--category--dXRJG";
export var isSelected = "BlogPage-module--isSelected--K-WWZ";
export var postsContainer = "BlogPage-module--postsContainer--G+-c1";
export var featuredPost = "BlogPage-module--featuredPost--VMtn4";
export var posts = "BlogPage-module--posts--l-S3a";
export var post = "BlogPage-module--post--v3k17";
export var isFeatured = "BlogPage-module--isFeatured--hYRR1";
export var isPointerPrimaryInput = "BlogPage-module--isPointerPrimaryInput--bXoed";
export var isFeaturedPostVisible = "BlogPage-module--isFeaturedPostVisible--UDXwa";
export var postImage = "BlogPage-module--postImage--qMP7g";
export var postContent = "BlogPage-module--postContent---MzBW";
export var postInfo = "BlogPage-module--postInfo--UeqT-";
export var postTitle = "BlogPage-module--postTitle--RMNLL";