import React, { useEffect, useState, useMemo } from 'react'
import classNames from 'classnames/bind'
import dayjs from 'dayjs'
import { RichText } from 'prismic'
import TransitionLink from 'gatsby-plugin-transition-link'

import Layout from 'layouts'

import useUIContext from 'context/ui'

import { Container } from 'components/ui/layout'
import { useScrollRig } from '@14islands/r3f-scroll-rig'
import ProjectCard from 'components/ui/project-card'
import SEO from 'components/seo'
import LettersAnimation from 'components/motion/letters-animation/LettersAnimation'
import ViewportEnter from 'components/motion/viewport-enter'

import shouldNavigate from 'lib/shouldNavigate'
import requestIdleCallback from 'lib/requestIdleCallback'

import * as s from './BlogPage.module.css'

const cn = classNames.bind(s)

const allCategory = 'all'

const BlogPage = ({
  data: {
    prismicBlog: {
      data: {
        page_meta_title,
        page_meta_description,
        page_meta_thumbnail,
        hero_title,
        hero_description,
        all_categories_text,
        cursor_text,
        featured_post,
      },
    },
    posts,
    categories,
  },
}) => {
  const startPageTransition = useUIContext(state => state.startPageTransition)
  const setHideHeader = useUIContext(s => s.setHideHeader)
  const { isCanvasAvailable, reflow } = useScrollRig()
  const isPointerPrimaryInput = useUIContext(state => state.isPointerPrimaryInput)
  const allPosts = useMemo(
    () =>
      posts?.edges?.filter(
        post => post?.node?.uid !== featured_post?.document?.uid && post?.node?.data?.is_listed === true,
      ),
    [posts, featured_post],
  )

  // const [selectedCategory, setSelectedCategory] = useState(allCategory)
  const [selectedCategory] = useState(allCategory)
  const [filteredPosts, setFilteredPosts] = useState(allPosts)
  const [isFeaturedPostVisible, setIsFeaturedPostVisible] = useState(false)

  useEffect(() => {
    if (selectedCategory === allCategory) {
      setFilteredPosts(allPosts)
    } else {
      setFilteredPosts(
        posts?.edges?.filter(
          post => post?.node?.category?.uid === selectedCategory && post?.node?.data?.is_listed === true,
        ),
      )
    }

    reflow()
  }, [selectedCategory])

  const renderPost = (post, isFeatured) => {
    const {
      document: {
        uid,
        data: { display_date, title, category, background_color, blob_color },
      },
    } = post

    const url = `/blog/${uid}`

    const postDate = dayjs(display_date)
    const isRecentPost = postDate.year() === dayjs().year()
    const formattedDate = postDate.format(`D MMM${isRecentPost ? '' : ' YYYY'}`)

    return (
      <div
        key={uid}
        className={cn('post', {
          isFeatured,
          isFeaturedPostVisible: isFeatured && isFeaturedPostVisible,
          isPointerPrimaryInput,
        })}
      >
        <ProjectCard
          key={uid}
          className={cn('postImage')}
          to={url}
          node={post}
          size="full"
          cursorText={cursor_text}
          hasText={false}
          offset={0}
        />

        <div className={cn('postContent')}>
          <TransitionLink
            to={url}
            exit={{
              delay: isCanvasAvailable ? 1 : 0,
              length: 0,
            }}
            entry={{
              delay: 0.1,
              trigger: () => {
                requestIdleCallback(() => {
                  setHideHeader(false)
                })
              },
            }}
            state={{ color: background_color }}
            onClick={e => {
              if (!shouldNavigate(e)) return
              startPageTransition({ color: blob_color, color2: background_color, slow: true })
              setHideHeader(true)
            }}
          >
            <span className={cn('postInfo')}>
              {category?.document?.data?.name} | {formattedDate}
            </span>

            {isFeatured && <h1 className={cn('postTitle')}>{title?.text}</h1>}
            {!isFeatured && <h2 className={cn('postTitle')}>{title?.text}</h2>}
          </TransitionLink>
        </div>
      </div>
    )
  }

  return (
    <Layout className={cn('aboutPage')} background="#F5F5F2" labCTA={false} fadeIn={true}>
      <SEO title={page_meta_title} description={page_meta_description} thumbnail={page_meta_thumbnail} />
      <Container className={cn('container')}>
        <div className={cn('wrapper')}>
          <LettersAnimation text={hero_title?.text || ''} className={cn('title')} />
          <div className={cn('description')}>{RichText.render(hero_description?.richText)}</div>
        </div>

        {/* <div className={cn('categories')}>
          <button
            className={cn('category', { isSelected: selectedCategory === allCategory })}
            onClick={() => setSelectedCategory(allCategory)}
          >
            {all_categories_text}
          </button>

          {categories?.edges.map(category => {
            const {
              name,
              _meta: { uid },
            } = category?.node

            return (
              <button
                key={uid}
                className={cn('category', { isSelected: selectedCategory === uid })}
                onClick={() => setSelectedCategory(uid)}
              >
                {name}
              </button>
            )
          })}
        </div> */}
      </Container>

      <Container className={cn('postsContainer')}>
        {selectedCategory === allCategory && (
          <ViewportEnter onEnter={() => setIsFeaturedPostVisible(true)} key="featured">
            <div className={cn('featuredPost')}>{renderPost(featured_post, true)}</div>
          </ViewportEnter>
        )}
        <div className={cn('posts')}>{filteredPosts.map(post => renderPost({ document: { ...post.node } }))}</div>
      </Container>
    </Layout>
  )
}

export default BlogPage
