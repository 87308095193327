import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from 'prismic'
import { prismicRepo } from '../../prismic-configuration'

import BlogPage from 'views/blog/BlogPage'

export const query = graphql`
  query BlogPageQuery {
    prismicBlog(uid: { eq: "blog" }, lang: { eq: "en-us" }) {
      _previewable
      uid
      data {
        page_meta_title
        page_meta_description
        page_meta_thumbnail {
          gatsbyImageData
        }
        hero_title {
          text
        }
        hero_description {
          richText
        }
        all_categories_text
        cursor_text
        featured_post {
          document {
            ...SlimBlogPostFragment
          }
        }
      }
    }
    posts: allPrismicBlogPost(sort: { fields: data___display_date, order: DESC }) {
      edges {
        node {
          ...SlimBlogPostFragment
        }
      }
    }
    categories: allPrismicBlogCategory {
      edges {
        node {
          ...BlogCategoryFragment
        }
      }
    }
  }
`

export default withPrismicPreview(BlogPage, [
  {
    repositoryName: prismicRepo,
    linkResolver,
  },
])
